/*
 * react-calendar-heatmap styles
 *
 * All of the styles in this file are optional and configurable!
 * The github and gitlab color scales are provided for reference.
 */

 .react-calendar-heatmap text {
    font-size: 10px;
    fill: rgb(255, 255, 255);
  }
  
  .react-calendar-heatmap .react-calendar-heatmap-small-text {
    font-size: 5px;
  }
  
  .react-calendar-heatmap rect:hover {
    stroke: #555;
    stroke-width: 1px;
  }
  
  /*
   * Default color scale
   */
  
  .react-calendar-heatmap .color-empty {
    fill: rgb(24, 24, 24);
  }
  
  .react-calendar-heatmap .color-filled {
    fill: #2468e5;
  }
  
  /*
   * Github color scale
   */
  
  .react-calendar-heatmap .color-github-0 {
    fill: rgb(44, 44, 44);
    border-radius: 4px;
  }
  .react-calendar-heatmap .color-github-1 {
    fill: #4891ff;
  }
  .react-calendar-heatmap .color-github-2 {
    fill: #2468e5;
  }
  .react-calendar-heatmap .color-github-3 {
    fill: #132DC7;
  }
  .react-calendar-heatmap .color-github-4 {
    fill: #1107A5;
  }
  
  /*
   * Gitlab color scale
   */
  
  .react-calendar-heatmap .color-gitlab-0 {
    fill: #ededed;
  }
  .react-calendar-heatmap .color-gitlab-1 {
    fill: #acd5f2;
  }
  .react-calendar-heatmap .color-gitlab-2 {
    fill: #7fa8d1;
  }
  .react-calendar-heatmap .color-gitlab-3 {
    fill: #49729b;
  }
  .react-calendar-heatmap .color-gitlab-4 {
    fill: #254e77;
  }