.App-profile-text{
  font-family: 'Saira Condensed', sans-serif;
  margin: 0px;
  padding: 8px;
  color: #fff;
}

.App-logo-text {
  font-family: 'Saira Condensed', sans-serif;
  margin: 0px;
  padding: 8px;
}

.App-card-text {
  font-family: 'Saira Condensed', sans-serif;
  margin: 0px;
  color: #565656;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.md {
  gap: 0;
  padding: 0px;
  margin: 0px;
  white-space: normal;
}

.md * {
  white-space: normal;
}

.md blockquote {
  padding: 0 14px 0 14px;
  margin: 0 0 40px 0;
  color: rgba(139, 148, 158);
  border-left: .25em solid rgb(48, 54, 61);
  height: max-content;
  box-sizing: border-box;
  display: block;
  margin-block-start: 0;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  white-space: normal;
}

.md blockquote * {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  display: block;
  height: min-content;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  white-space: normal;
  padding: 0px;
  margin: 0px;
}

.md a {
  color:#4c86fb;
  text-decoration: none;
}

.md a:hover {
  text-decoration: underline;
}

.md code {
  border-radius: 6px;
  background-color: rgba(110, 118, 129, 0.4);
  padding: .2em .4em;
  font-size: smaller;
}

.md img {
  width: 90%;
  margin: 0;
  max-width: 400px;
}

.md pre {
  border-radius: 6px;
  background-color: rgba(110, 118, 129, 0.4);
  padding: .2em .4em;
  font-size: smaller;
}

.md pre code {
  background-color: transparent;
}

.md h1, .md h2 {
  border-bottom: 1px solid rgba(110, 118, 129, 0.4);
}

.md table {
  border-collapse: collapse;
}

.md th, .md td {
  border: 1px solid rgb(128, 128, 128);
  text-align: left;
  padding: 8px;
}

.md tr:nth-child(even) {
  background-color: rgba(110, 118, 129, 0.1);
}

.hover_slide_right {
  position: absolute;
  top: 20px;
  right: -100%;
  max-width: 140px;
  z-index: 1;
  transition: all .5s ease-in-out;
  transition-delay: 0;
  opacity: 0;
}

@keyframes rtl {
  from {
    right: -100%;
    opacity: 0;
  }
  to {
    right: 37%;
    opacity: 1;
  }
}

.slide_right {
  position: absolute;
  top: 60px;
  right: -100%;
  max-width: 27%;
  z-index: 3;
  opacity: 0;
  animation-name: rtl;
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes ltr {
  from {
    left: -100%;
    opacity: 0;
  }
  to {
    left: 22%;
    opacity: 1;
  }
}

.slide_left {
  position: absolute;
  top: 60px;
  left: -100%;
  max-width: 27%;
  z-index: 1;
  opacity: 0;
  animation-name: ltr;
  animation-duration: 0.5s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.MuiRating-iconEmpty  .npnm {
  opacity: 0.4;
}

.npnm {
  margin: 0px;
  padding: 0px;
}

.plan {
  max-width: 65%;
  white-space: pre;
}